import { IAttivita } from '../../types';

import { creaStringStandardPerAttivita } from '../../hooks/attivita/creaStringStandardPerAttivita';

export default function factoryAttivitaRendicontazioneIndicatori(attivita: IAttivita) {

    let codiceInterpretato = '';

    switch (attivita.route) {

        case 'RendicontazioneIndicatori.crea':
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">creato</span> un indicatore</a>`
            break;

        case 'RendicontazioneIndicatori.modifica':
            codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">modificato</span> un indicatore</a>`
            break;

        case 'RendicontazioneIndicatori.elimina':
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">eliminato</span> un indicatore`
            break;

        case 'RendicontazioneIndicatori.valida':
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">validato</span> un indicatore`
            break;

        case 'RendicontazioneIndicatori.invalida':
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">rimosso la validazione</span> da un indicatore`
            break;

        default:
            codiceInterpretato = creaStringStandardPerAttivita(attivita)

    }

    return codiceInterpretato;

}
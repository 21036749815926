export const it_modulo_gestione_utenti = {
    SchedaUtente: {
        titoloAdmin: 'Utente amministratore',
        schedaUtente: 'Scheda utente',
        accountCreato: 'Account creato il',
        gruppiUtenti: 'Gruppi a cui appartieni',
        nessunoGruppiUtenti: ' Non appartieni a nessun gruppo',
        moduliUtenti: 'Moduli a cui hai accesso',
        nessunoModuli: 'Non appartieni a nessun gruppo',
    },

    FormAggiungiUtentiGruppo: {
        aggiungiUtentiGruppo: 'Aggiungi utenti al gruppo',
        testoAggiungiUtenti: 'Un gruppo può contenere più utenti. Ad ogni gruppo possono essere associati i permessi per agire sui diversi moduli dell\'applicazione.',
        utenti: 'Utenti',
        selezioneUtenti: 'Seleziona utenti',
        tuttiSelezionati: 'Tutti gli utenti selezionati',
        selezioneTutti: 'Seleziona tutti',
    },

    FormCreaGruppo: {
        creaNuovoGruppo: 'Crea un nuovo gruppo',
        messaggioNomeLimitizione: 'Non può contenere spazi, lettere maiuscole o caratteri speciali',
        gruppoCreatoSuccesso: 'Gruppo creato con successo',
        creaGruppo: 'Crea gruppo',
    },

    GruppoContextMenu: {
        gruppoEliminatoSucesso: 'Gruppo eliminato con successo',
        modificaGruppo: 'Modifica gruppo',
        eliminaGruppo: 'Elimina gruppo',
        mesaggioElimina: 'Sei sicuro di voler eliminare il gruppo',
        messaggioCompleto: 'Gli utenti associati non verranno eliminati, ma perderanno i privilegi associati al gruppo.',
        testoEliminaButtone: 'Elimina il gruppo',
    },

    GruppoTile: {
        utente: 'utenti',
        collegaUtente: 'Collega un nuovo utente',
    },

    ModificaGruppo: {
        testoModifica: 'Modifica il gruppo',
        placeHolder: 'es: gruppo_1',
    },

    FormCreaUtenti: {
        creaUnUtente: 'Crea un utente ',
        selezioneGruppi: 'Seleziona gruppi',
        tuttiGruppiSelezionati: 'Tutti i gruppi selezionati',
        utenteCreatoSucesso: 'Utente creato con successo',
        creaUtente: 'Crea utente',
    },

    FormModificaUtente: {
        modificaUtente: 'Modifica l\'utente',
        modificaUtenteText: 'Modificando l\'utente',
        testoModifica: 'potrai cambiare i suoi dati personali, i gruppi a cui appartiene e conseguentemente i privilegi che otterrà all\'interno della piattaforma.',
        disabilitaTemporaneamente: 'Disabilita temporaneamente',
    },

    PannelloRichieste: {
        richiesteAperte: 'Richieste aperte',
        nessunoRichiesta: 'Nessuna richiesta aperta',
        richiesteGestite: 'Richieste gestite',
    },

    PannelloUtenti: {
        utentiSenzaGruppi: 'Utenti senza gruppo',
        utentiTrovati: 'utenti trovati',
        nessunoGruppoTrovato: 'Nessun gruppo trovato',
        aggiungiNuovoUtente: 'Aggiungi nuovo utente',
    },

    TileRichiestaUtente: {
        richiestaRicevuta: 'Richiesta ricevuta il',
        acettataRichiesta: 'Accetta richiesta',
        riffutataRichiesta: 'Rifiuta richiesta',
    },

    FormRifiutaUtente: {
        rifiutaRichesta: 'Rifiuta richiesta per l\'utente',
        rifiutaQuestaRichesta: 'Rifiuta questa richiesta.',
        ragioneDelRifiuta: 'Ragione del rifiuto',
        messaggioRagione: 'Specifica la ragione del rifiuto',
        statoRifuitato: 'L\'utente è stato rifiutato',
        confirmaRifuitato: 'Conferma rifiuto',
    },

    UtenteContextMenu: {
        utenteEliminatoSucesso: 'Utente eliminato con successo',
        modificaUtente: 'Modifica utente',
        eliminaMessagio: 'Sei sicuro di voler eliminare l\'utente',
        eliminaTestoMessagio: 'Perderà il suo account in modo permanente e non potrà più effettuare il login. Tutti i dati da lui inseriti nella piattaforma non verranno comunque eliminati.',
        eliminaUtente: 'Elimina l\'utente',
        eliminaUtenteButtone: 'Elimina utente',
    },

    TileUtente: {
        utenteDisabilitato: 'Utente temporaneamente disabilitato',
        registroIl: 'Registrato il',
        emailVerificata: 'E-mail verificata',
        emailNonVerificat: 'E-mail non verificata',
        passwordImpostata: 'Password impostata',
        passwordNonImpostata: 'Password non impostata',
    },

}
import React from 'react'
import { FaAngleLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC<{testo?: string, destinazione?: string}> = ({ testo, destinazione }) => {

    const navigate = useNavigate();

    const handleNavigazione = () => {
        if(destinazione) {
            navigate(destinazione)
        } else {
            navigate(-1)
        }
    }

    return (
        <button
            className="text-white p-2 flex items-center gap-2 font-bold rounded-sm bg:dark-accent"
            onClick={() => handleNavigazione()}
        >   
            <FaAngleLeft />
            <span>{testo ? testo : 'Torna indietro'}</span>
        </button>
    )
}

export default BackButton;
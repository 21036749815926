import React from 'react'
import { useTranslation } from 'react-i18next'
import { IFattoreRendicontabile } from '../../../types';
import RendicontazioneIndicatoreForm from './RendicontazioneIndicatoreForm';
import RendicontazioneIndicatoreTabellaForm from './RendicontazioneIndicatoreTabellaForm';
import { observer } from 'mobx-react-lite';
import AvanzamentoRendicontazioneFattore from './AvanzamentoRendicontazioneFattore';
import StatoAvanzamentoCompletamentoIndicatori from '../../../components/bilanci/StatoAvanzamentoCompletamentoIndicatori';
import { indicatoriFattori } from '../helpers/indicatoriFattori';

const TileRendicontazioneFattore: React.FC<{
  fattore: IFattoreRendicontabile,
  bilancioAnno: number,
  businessUnitNome: string,
  ruolo: number
}> = observer(({
  fattore,
  bilancioAnno,
  businessUnitNome,
  ruolo
}) => {

  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col items-start h-full">

      {fattore.tipo === 'fattore' &&
        <AvanzamentoRendicontazioneFattore stato={
          fattore.indicatori.length === 0 ? 'daRendicontare'
            :
            fattore.indicatori[0].validato ? 'validato' : 'rendicontato'
        } />
      }

      <div className="p-4 md:p-5 w-full h-full flex items-stretch justify-between flex-col">

        <div className="flex flex-col items-start w-full">

          <p className="font-bold text-accent dark:text-dark-accent mb-2">{fattore.codice}</p>

          {fattore.nome && fattore.nome !== fattore.nome_esteso && <h2 className="text-lg">{fattore.nome}</h2>}
          {fattore.nome_esteso && <h2 className="text-xl font-bold">{fattore.nome_esteso}</h2>}

          {
            fattore.tipo === 'fattore' &&
            <p className="mb-2 font-bold text-accent dark:text-dark-accent">
              {
                fattore.tipo_indicatore ?
                  <span>{t('ModuloFattoriEsg.TileRendicontazioneFattore.tipologiaRendicontazioneRichiesta')} <span className="capitalize">{fattore.tipo_indicatore}</span></span>
                  :
                  t('ModuloFattoriEsg.TileRendicontazioneFattore.fattoreRendicontatoLiberamente')
              }
            </p>
          }

          {
            fattore.tipo === 'tabella' && fattore.tipo_indicatore &&
            <p className="mb-2 font-bold text-accent dark:text-dark-accent">
              <span>{t('ModuloFattoriEsg.TileRendicontazioneFattore.tipologiaRendicontazioneRichiesta')} <span className="capitalize">{fattore.tipo_indicatore}</span></span>
            </p>
          }

          {
            fattore.descrizione &&
            <p><span className="font-bold">{t('common.descrizione')}: </span>{fattore.descrizione}</p>
          }

          {
            fattore.tipo === 'tabella' &&
            <div className="w-full my-2">
                <StatoAvanzamentoCompletamentoIndicatori
                  indicatori_totali={fattore.sotto_fattori.filter(f => f.tipo === 'riga').length * fattore.sotto_fattori.filter(f => f.tipo === 'colonna').length}
                  indicatori_rendicontati={indicatoriFattori(fattore.sotto_fattori).length}
                  indicatori_validati={indicatoriFattori(fattore.sotto_fattori).filter(i => i.validato).length}
                />
            </div>
          }

        </div>

        {
          fattore.tipo === 'fattore' &&
          <div className="w-full">
            <RendicontazioneIndicatoreForm
              indicatore={fattore.indicatori[0]}
              bilancio_anno={bilancioAnno}
              business_unit={businessUnitNome}
              ruolo={ruolo}
              disabilitato={ruolo > 0 ? false : true}
              fattore1={fattore}
              fattore_tipo_indicatore={fattore.tipo_indicatore}
              unita_misura_predefinita={fattore.unita_misura ? fattore.unita_misura : ''}
              risposte_multiple_predefinite={fattore.risposte_multiple ? fattore.risposte_multiple : []}
              numero_risposte_multiple_possibili={fattore.num_risposte_multiple ? fattore.num_risposte_multiple : fattore.risposte_multiple.length}
            />
          </div>
        }

        {
          fattore.tipo === 'tabella' &&
          <div className="w-full">
            <RendicontazioneIndicatoreTabellaForm
              tabella={fattore}
              business_unit_nome={businessUnitNome}
              bilancio_anno={bilancioAnno}
              ruolo={ruolo}
            />
          </div>
        }
      </div>

    </div >
  )
})

export default TileRendicontazioneFattore;
import React, { ReactNode, useState } from 'react'
import { FaAngleDown, FaPlus } from 'react-icons/fa';

const FiltroRendicontazioneBusinessUnit: React.FC<{
    nome: string,
    apertoDefault?: boolean,
    bottoneReset: boolean
    reset: () => void,
    children: ReactNode,
}> = ({
    nome,
    apertoDefault = false,
    bottoneReset,
    reset,
    children,
}) => {

        const [aperto, setAperto] = useState(apertoDefault)

        return (
            <div className="border-b border-lightgray-dd dark:border-black">
                <div className="h-7 pl-4 flex items-center justify-between cursor-pointer">
                    <h2 className={`${bottoneReset && 'font-bold'}`}>{nome}</h2>

                    <div className="flex items-stretch h-full">
                        {bottoneReset &&
                            <div className="duration-300 cursor-pointer h-full w-6 flex items-center justify-center hover:bg-lightgray-d dark:hover-bg-darkgray-d" onClick={() => reset()}>
                                <div className="rotate-45">
                                    <FaPlus />
                                </div>
                            </div>}
                        <div className="p-2 duration-300 cursor-pointer h-full w-6 flex items-center justify-center hover:bg-lightgray-d dark:hover-bg-darkgray-d" onClick={() => setAperto(!aperto)}>
                            <div className={`${aperto && 'rotate-180'}`}>
                                <FaAngleDown />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`duration-300 px-4 dark:border-black overflow-hidden ${aperto ? 'py-4 border-t border-lightgray-dd dark:border-black' : 'h-[0px]'}`}>

                    {children}

                </div>
            </div>
        )
    }

export default FiltroRendicontazioneBusinessUnit;

import axios from 'axios'
import { config } from '../../../config'
import { ILingua } from '../../../types'

//Riceve lista privilegi senza parent
export const getLinguaLista = () => {
    return axios.get(`${config.API_URI}lingua`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve singolo lingua
export const getLingua = (codice: string) => {
    return axios.get(`${config.API_URI}lingua/${codice}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Crea un lingua
export const creaLingua = (lingua: ILingua) => {
    return axios.post(`${config.API_URI}lingua`, {
        codice: lingua.codice,
        nome: lingua.nome,
        bandiera: lingua.bandiera,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un lingua
export const modificaLingua = (lingua: ILingua) => {
    return axios.put(`${config.API_URI}lingua/${lingua.codice_originale ? lingua.codice_originale : lingua.codice}`, {
        codice: lingua.codice,
        nome: lingua.nome,
        bandiera: lingua.bandiera,
        codice_originale: lingua.codice_originale,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina un lingua
export const eliminaLingua = (codice: string) => {
    return axios.delete(`${config.API_URI}lingua/${codice}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}



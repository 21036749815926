import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLinguaLista } from '../../moduli/gestioneLingue/rest/gestioneLingue'
import { ILingua } from '../../types'
import "/node_modules/flag-icons/css/flag-icons.min.css";

const CambioLingua = () => {

    const [listaLingue, setListaLingue] = useState<ILingua[]>([])

    useEffect(() => {
        getLinguaLista().then((res) => {
            setListaLingue(res.data.data)
        })
    }, [])

    const { i18n } = useTranslation()

    const handleCambiaLingua = (lang: string) => {
        i18n.changeLanguage(lang)
    }

    return (
        <div className={`flex bg-lightgray-d p-1 rounded-md ${listaLingue.length === 1 && 'hidden'}`}>
            <div className="overflow-hidden rounded-md flex w-full">
                {listaLingue.map(lingua => (
                    <div
                        key={lingua.codice}
                        onClick={() => handleCambiaLingua(lingua.codice)}
                        className={`grow text-center p-1 cursor-pointer hover:bg-lightgray-dd duration-100 flex gap-2 items-center justify-center ${i18n.language === lingua.codice && 'bg-accent text-white pointer-events-none'}`}
                    >
                        <span className={`scale-[0.8] fi fi-${lingua.bandiera}`} />
                        <span>{lingua.nome}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CambioLingua;
import React from 'react'
import { useStores } from '../../hooks/useStores'

//Components
import { FaCheck } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'


const RichiesteArchiviateModal = () => {

  const styles = {
    box: "absolute bg-white rounded-sm shadow-lg top-6 md:right-0 max-h-[150px] overflow-y-auto bg-white p-3 shadow-xl"
  }

  const { gestioneUtenti } = useStores();

  return (
    <div className={styles.box}>

      <div className="gap-2 flex flex-col">
        {gestioneUtenti.richieste.filter(richiesta => richiesta.gestita === true).map(richiesta => (
          <div className={`flex gap-2 items-center p-2 justify-between ${richiesta.accettata ? 'bg-accent dark:bg-dark-accent' : 'bg-red'} text-white`}>

            <div className="whitespace-nowrap flex items-center gap-3">
              {richiesta.accettata ? <FaCheck /> : <AiOutlineClose />}
              <span>{richiesta.nome} {richiesta.cognome}</span>
            </div>

          </div>
        ))}
      </div>

    </div>
  )
}


export default RichiesteArchiviateModal;
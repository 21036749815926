import React, { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm, Controller } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { observer, } from 'mobx-react-lite'
import { runInAction } from 'mobx'
import { config } from '../../config'
import { IGruppoForm, IRichiesta, IUtente } from '../../types'
import { getGruppi } from './rest/gestioneGruppi'
import { getLinguaLista } from '../gestioneLingue/rest/gestioneLingue'

//Components
import TestoErrore from '../../components/common/TestoErrore'
import InfoBox from '../../components/common/InfoBox'
import ErrorBox from '../../components/common/ErrorBox'
import { MultiSelect } from 'react-multi-select-component'
import SpinnerButton from '../../components/common/SpinnerButton'
import Select from 'react-select'

const CreaUtenteForm: React.FC<{ richiesta?: IRichiesta }> = observer(({ richiesta }) => {

  const { t } = useTranslation();

  const [lingue, setLingue] = useState<[]>([])

  useEffect(() => {
    getLinguaLista().then((res) => {
      setLingue(res.data.data)
    })
  }, [])

  lingue.map((lingua: any) => {
    lingua.value = lingua.codice
    lingua.label = lingua.nome
  })

  console.log(lingue)

  const { gestioneUtenti } = useStores();

  //Istanzia Hook Form
  const { watch, register, handleSubmit, control, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      'nome': richiesta ? richiesta.nome : '',
      'cognome': richiesta ? richiesta.cognome : '',
      'azienda': richiesta ? richiesta.azienda : '',
      'email': richiesta ? richiesta.email : '',
      'telefono': richiesta ? richiesta.telefono : '',
      'lingua': richiesta ? richiesta.lingua : '',
      'gruppi': []
    }
  })

  //Gestisce submit
  const onSubmit = () => {
    gestioneUtenti.creaUtente(watch() as IUtente).then(() => {

      //Se l'utente è stato creato con successo gestisce la richiesta
      if (!gestioneUtenti.creatingUtenteError) {
        gestioneUtenti.gestisciRichiesta(richiesta as IRichiesta, '', true)
      }

    })
  }

  //Pulisce lo stato in unmount
  useEffect(() => () => {
    runInAction(() => gestioneUtenti.creatingUtenteError = '')
  }, [gestioneUtenti]);

  //Lista di tutti i gruppi
  const [gruppi, setGruppi] = useState<any>([])

  //Lista dei gruppi selezionati
  const [gruppiSelezionati, setGruppiSelezionati] = useState([]);

  //Ottiene lista gruppi
  useEffect(() => {
    getGruppi().then(res => {
      setGruppi(res.data.data)
    })
  }, [])

  //Ristruttura dati per label MultiSelect
  gruppi.forEach((gruppo: IGruppoForm) => {
    gruppo.value = gruppo.codice
    gruppo.label = `${gruppo.nome}`
  })

  return (
    <div>

      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloGestioneUtenti.FormCreaUtenti.creaUtente')}</h2>
        <p className="text-text-light">{t('ModuloGestioneUtenti.FormCreaUtenti.creaUnUtente')} {richiesta && `per ${richiesta.nome}`}.</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="w-full flex gap-1">
          <div className="form">
            <label htmlFor="">{t('common.nome')}</label>
            <input autoFocus id="crea-utente-nome" {...register("nome", { required: t('common.fornisciNome') })} placeholder={t('common.nome')} type="text" />

            {errors.nome && <TestoErrore errore={errors.nome.message} />}
          </div>

          <div className="form">
            <label htmlFor="">{t('common.cognome')}</label>
            <input id="crea-utente-cognome" {...register("cognome", { required: t('common.fornisciCognome') })} placeholder={t('common.cognome')} type="text" />

            {errors.cognome && <TestoErrore errore={errors.cognome.message} />}
          </div>
        </div>

        <div className="form">
          <label htmlFor="">{t('common.azienda')}</label>
          <input id="crea-utente-azienda" {...register("azienda", { required: t('common.specificaAzienda') })} placeholder={t('common.azienda')} type="text" />

          {errors.azienda && <TestoErrore errore={errors.azienda.message} />}
        </div>

        <div className="w-full flex gap-1">
          <div className="form">
            <label htmlFor="">{t('common.eMail')}</label>
            <input id="crea-utente-email" {...register("email", { required: t('common.fornisciEmail') })} placeholder={t('common.eMail')} type="email" />

            {errors.email && <TestoErrore errore={errors.email.message} />}
          </div>

          <div className="form">
            <label htmlFor="">{t('common.telefono')}</label>
            <input id="crea-utente-telefono" {...register("telefono", { required: t('common.fornisciTelefone'), pattern: { value: config.REGEX_TELEFONO, message: t('common.telefonoMessagio') } })} placeholder={t('common.telefono')} type="text" />

            {errors.telefono && <TestoErrore errore={errors.telefono.message} />}
          </div>
        </div>

        <div className="form">
          <label>{t('common.lingua')}</label>
          <Controller
            name="lingua"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={lingue}
                defaultValue={lingue.find((l: any) => l.value === richiesta?.lingua)}
                onChange={(e: any) => onChange(e.value)}
              />
            )}
          />
        </div>

        <div className="w-full">
          <div className="form">
            <label htmlFor="">{t('common.gruppi')}</label>
            <Controller
              control={control}
              name="gruppi"
              render={({
                field: { onChange },
              }) => (
                <MultiSelect
                  className="dark:text-black"
                  options={gruppi}
                  value={gruppiSelezionati}
                  onChange={(e: SetStateAction<never[]>) => { setGruppiSelezionati(e); onChange(e) }}
                  labelledBy={t('ModuloGestioneUtenti.FormCreaUtenti.selezioneGruppi')}
                  overrideStrings={{
                    selectSomeItems: t('ModuloGestioneUtenti.FormCreaUtenti.selezioneGruppi'),
                    allItemsAreSelected: t('ModuloGestioneUtenti.FormCreaUtenti.tuttiGruppiSelezionati'),
                    selectAll: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneTutti'),
                    search: t('common.cerca'),
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="form">
          {
            (isSubmitSuccessful && !gestioneUtenti.creatingUtenteError && !gestioneUtenti.creatingUtente) ?
              <InfoBox messaggio={t('ModuloGestioneUtenti.FormCreaUtenti.utenteCreatoSucesso')} id={"crea-utente-success"} />
              :
              <div className="form pt-4">
                {!gestioneUtenti.creatingUtente ? <input type="submit" value={t('ModuloGestioneUtenti.FormCreaUtenti.creaUtente')} id="crea-utente-submit" /> : <SpinnerButton />}
              </div>
          }

          {
            gestioneUtenti.creatingUtenteError &&
            <div className="mb-2">
              <ErrorBox errore={gestioneUtenti.creatingUtenteError.toString()} />
            </div>
          }
        </div>

      </form>
    </div>
  )
})

export default CreaUtenteForm;
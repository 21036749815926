import React, { useState } from "react"
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from "../../hooks/useStores"
import dayjs from "dayjs"
import { IUtente } from "../../types"
import useOttieniPrivilegioModulo from "../../hooks/useOttieniPrivilegioModulo"

//Components
import { CgClose, CgCheck } from "react-icons/cg"
import { FaEllipsisV } from "react-icons/fa"
import UtenteContextMenu from './UtenteContextMenu'

const UtenteTile: React.FC<{ utente: IUtente }> = ({ utente }) => {
  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo();
  const [contextMenu, setContextMenu] = useState(false)

  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  const { auth } = useStores();

  const styles = {
    wrapper: `
        bg-white dark:bg-darkgray-dd p-5 rounded-sm shadow-xl flex flex-col overflow-hidden
        ${utente.disabilitato && "border border-red dark:border-accent-red"}
        ${utente.id === auth.utente?.id && "opacity-70 pointer-events-none"}
      `,
  }

  return (
    <div className={styles.wrapper}>
      <div>
        {utente.disabilitato && <p className="text-xs text-red dark:text-dark-red mb-2">{t('ModuloGestioneUtenti.TileUtente.utenteDisabilitato')}</p>}

        <div className="flex items-center justify-between">
          <p className="text-xs pb-3 w-8">{t('ModuloGestioneUtenti.TileUtente.registroIl')} <br /> {dayjs(utente.created_at).locale('it').format('DD MMMM YYYY')}</p>

          {privilegio >= 3 &&
            <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
              <FaEllipsisV className="hover:opacity-70" />

              {contextMenu && <UtenteContextMenu utente={utente} />}

            </div>
          }
        </div>

        <hr className="border-lightgray-dd" />
        <div className="mt-4">
          <h4 className="font-bold text-lg">{utente.nome} {utente.cognome}</h4>
          <p>{utente.azienda}</p>
          <p>{utente.email}</p>
          <p>{utente.telefono}</p>

          <div className="flex gap-1 items-center">
            <p>Lingua utilizzata:</p>
            <span className="flex items-center gap-1">{utente.lingua} <span className={`fi fi-${utente.lingua} scale-[0.8]`} /></span>
          </div>

          <div className={`mt-2 flex items-center gap-1 ${utente.is_verified ? 'text-accent dark:text-dark-accent' : 'text-red dark:text-dark-red'}`}>
            {utente.is_verified ? <CgCheck size={20} /> : <CgClose />}
            <p>{utente.is_verified ? t('ModuloGestioneUtenti.TileUtente.emailVerificata') : t('ModuloGestioneUtenti.TileUtente.emailNonVerificat')}</p>
          </div>

          <p className={`mt-2 flex items-center gap-1 ${utente.attivo ? 'text-accent dark:text-dark-accent' : 'text-red dark:text-dark-red'}`}>
            {utente.attivo ? <CgCheck size={20} /> : <CgClose />}
            {utente.attivo ? t('ModuloGestioneUtenti.TileUtente.passwordImpostata') : t('ModuloGestioneUtenti.TileUtente.passwordNonImpostata')}
          </p>
        </div>

      </div>
    </div>
  )
}

export default UtenteTile;
export const en_modulo_gestione_moduli = {
    Archivio: {
        nessunoModulo: 'No module available',
        nuovoModulo: 'Add new module',
        riordinaModuli: 'Reorder modules',
        passaGestionePrivilegi: 'Go to privileges management',
    },

    ContextMenu: {
        modificaModulo: 'Edit module',
        modulo: 'module',
        attiva: 'Enable',
        disattiva: 'Disable',
        modificaPremessi: 'Edit module privileges',
        eliminaModulo: 'Delete module',
    },

    Form: {
        modificaModulo: 'Edit module',
        testoModifica: 'Edit module data',
        testModificaCompleto: 'associates groups to allow users to access features.',
        testoCrea: 'Create a new module and associate it to groups to allow to access it.',
        testoPlaceholderCodice: 'modulo_1',
        testoPlaceholderNome: 'Module name',
        testoPlaceholderDescrizione: 'Module description',
        icona: 'Icon',
        selezioneIcona: 'Select an icon',
        moduloGenitore: 'Parent module',
        visibileNavBar: 'Visible on navbar',
        visibileHeader: 'Visibile on header',
        disattivaModulo: 'Disable modulo',
        messagioCreatoSucesso: 'Modules successfully created',
        creaModulo: 'Create module',
    },

    Tile: {
        privileiGenitore: 'Privilege inherited from a parent module',
        privilegiDisattivi: 'Privilege disabled',
    },

    FormPrivilegi: {
        testoAttribuisci: 'Assign privileges to this module',
        testoDescrizione: 'You can determine which groups will have access to the module and with what privilege.',
        tuttiGruppi: 'All groups selected',
        unoGruppo: 'Select a group and add it to grant access to the module',
        selezionaGruppo: 'Select group',
        aggiungiGruppi: 'Add the group',
        privilegi: 'Privileges',
        attivaPrivilegi: 'Enable privilege',
        salavaPrivilegi: 'Save privileges',
    },

    Ordinamento: {
        riordinaVisualizzazioni: 'Rearranges the order in which forms are displayed throughout the platform',
        riordinaNavbar: 'Rearrange the modules in the Navbar',
        testoNavBar: 'You can change the sorting in which the modules appear in the navbar menu',
        spostaNelMenuUtente: 'Move to User menu',
        riordinaNelMenuUtente: 'Rearrange the modules in the User menu',
        testoMenuUtente: 'You can change the sorting in which the modules appear in the User menu',
        spostaNelNavBar: 'Move to Navbar',
        spostaNeiModuliNonVisibile: 'Move to "not visible" modules',
        moduliNonVisibili: 'Not visibile modules',
        testModuliNonVisibile: 'These are the modules that are not accessible from either the navbar or the User menu',
    },
}
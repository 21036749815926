import React, { useEffect, useState } from 'react'

//Data
import { useStores } from '../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { Outlet, useLocation, Navigate, Link } from 'react-router-dom'

//Components
import { FaAngleRight, FaInfoCircle } from 'react-icons/fa'
import Footer from '../components/common/Footer'
import TextLoader from '../components/common/TextLoader'

const Modulo: React.FC<{ titolo?: string, descrizione?: string }> = observer(({ titolo, descrizione }) => {

  const styles = {
    page: "relative flex flex-col min-h-full",
    pageHeaderWrapper: "py-6 sticky top-0 bg-lightgray-d dark:bg-darkgray-d w-inherit justify-center flex flex-col box-content",
    pageHeaderContent: "p-2 md:p-4 flex flex-col",
    titolo: "text-4xl font-bold mb-2",
    descrizione: "text-text-light dark:text-dark-text-light text-xl flex items-center gap-1",
    pageContent: "bg-lightgray dark:bg-darkgray p-2 md:p-4 relative ipe-shad grow border-t border-lightgray-dd dark:border-darkgray-dd"
  }

  const { pathname } = useLocation()

  const breadcrumbs = pathname.split('/')

  const { ui, moduli } = useStores()

  //Effettua il redirect a home se il modulo non è attivo
  if (moduli.moduloAttivo?.attivo === false) { return <Navigate replace to="/" /> }

  useEffect(() => {

    moduli.getModulo(breadcrumbs[3])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const [itemsBreadcrumb, setItemsBreadcrumb] = useState<any>([])

  //Crea elementi per loop breadcrumbs linkate
  useEffect(() => {

    setItemsBreadcrumb([])

    breadcrumbs.forEach((item, i) => {

      let uri = breadcrumbs.slice(0, i + 1).toString().replaceAll(',', '/')

      setItemsBreadcrumb((itemsBreadcrumb: any) => [...itemsBreadcrumb, {
        etichetta: item,
        uri: uri
      }])

    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div className={styles.page} onMouseEnter={() => ui.unmountFollowMouse()}>
      <div className={styles.pageHeaderWrapper}>
        <div className={styles.pageHeaderContent}>
          <div className="flex gap-1 mb-1 items-center flex-wrap">
            {itemsBreadcrumb.map((item: any, index: number) => (
              <>
                {index === itemsBreadcrumb.length - 1 ?
                  <p key={item.uri} className="font-bold">{item.etichetta}</p>
                  :
                  <Link key={item.uri} className={`${index < 3 && 'pointer-events-none opacity-70 text-black'}`} to={item.uri}>{item.etichetta}</Link>
                }
                {index !== 0 && index !== itemsBreadcrumb.length - 1 && <FaAngleRight size={8} />}
              </>
            ))}
          </div>
          <h1 className={styles.titolo}>
            {moduli.gettingModuloAttivo ? <TextLoader width={250} /> : moduli.moduloAttivo?.nome}
          </h1>
          <p className={styles.descrizione}>
            {moduli.gettingModuloAttivo ?
              <TextLoader width={200} />
              :
              <>
                {moduli.moduloAttivo?.descrizione}
                <Link to={`/app/modulo/dashboard/lista-moduli?modulo=${moduli.moduloAttivo?.codice}`}>
                  <FaInfoCircle />
                </Link>
              </>
            }
          </p>
        </div>
      </div>

      <div className={styles.pageContent}>
        <Outlet />
      </div>

      <Footer />
    </div>
  )
})

export default Modulo;
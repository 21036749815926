import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../../../hooks/useStores'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { IBilancio } from '../../types'
import { observer } from 'mobx-react-lite'

//Components
import { FaInfoCircle } from 'react-icons/fa'
import AggiungiButton from '../../../../components/common/AggiungiButton'
import BilanciForm from '../../components/bilanci/BilanciForm'
import BilancioTile from '../../components/bilanci/BilancioTile'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'

const ArchivioBilanciFattori = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()
    const { t } = useTranslation();
    const { bilanci } = useModuloESGStores()
    const { ui } = useStores();

    useEffect(() => {

        bilanci.getBilanci()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        bilanci.bilancioCreato,
        bilanci.bilancioEliminato,
        bilanci.bilancioModificato,
    ])

    return (
        <div>
            <div className="mt-2">
                {
                    bilanci.bilanci && bilanci.bilanci.length > 0 ?
                        <div className="flex flex-col gap-2">
                            {bilanci.bilanci && bilanci.bilanci.map((bilancio: IBilancio) => (
                                <BilancioTile bilancio={bilancio} key={bilancio.anno} />
                            )
                            )}
                        </div>
                        :
                        <h3 className="flex items-center justify-center gap-2 text-2xl my-8 text-text-light">
                            <FaInfoCircle /> { t('ModuloDashboard.Archivio.nessunBilancioPresente') }
                        </h3>
                }
            </div>

            {privilegio >= 2 &&
                <div className="w-full mt-2" onClick={() => ui.mountAsideModal(<BilanciForm />)} >
                    <AggiungiButton testo={ t('common.aggiungiNuovoBilancio') } />
                </div>
            }
        </div>
    )
})

export default ArchivioBilanciFattori;
import React, { useEffect } from 'react'

//Data
import { Navigate, Outlet, redirect, useParams } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useModuloRendicontazioneBilanciStores } from '../context/useModuloRendicontazioneBilanciStores'

//Components
import Spinner from '../../../../../components/common/Spinner'
import ListaBusinessUnitsRendicontabili from '../components/ListaBusinessUnitsRendicontabili'


const SchedaRendicontazioneBilancio = observer(() => {

  const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
  const { bilancio_anno, business_unit_nome } = useParams()

  const { t } = useTranslation();

  useEffect(() => {

    rendicontazione_bilanci.settaBilancio(bilancio_anno as string)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //Aggiorna bu attiva

  if (
    rendicontazione_bilanci.bilancio &&
    rendicontazione_bilanci.bilancio.business_units_rendicontabili.length > 0 &&
    !business_unit_nome
  ) {
    return <Navigate to={rendicontazione_bilanci.bilancio.business_units_rendicontabili[0].nome} />
  }

  return (
    <div>
      <div className="flex flex-col gap-2">

        {rendicontazione_bilanci.gettingBilancio &&
          <div className="flex flex-col gap-2 items-center justify-center py-4">
            <Spinner colore='blue' />
            {t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.caricandoFattoriESG')}
          </div>
        }

        {rendicontazione_bilanci.bilancio && !rendicontazione_bilanci.gettingBilancio &&
          <div>

            <div>
              <h2 className="text-2xl font-bold">
                {t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.schedaDirendicontazione')}
                {rendicontazione_bilanci.bilancio.anno}
              </h2>
              <p className="mt-2">
                {t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.inQuestoSezione')}
              </p>
            </div>

            <div>

              <ListaBusinessUnitsRendicontabili />

              <Outlet />

            </div>

          </div>
        }
      </div>
    </div>
  )
})

export default SchedaRendicontazioneBilancio;

import React, { useEffect, useState } from 'react'
import { getBilanciRendicontazione } from '../rest/rendicontazione_per_business_unit'
import { IBilancioRendicontabile } from '../../../types'
import BilancioTile from '../../../components/bilanci/BilancioTile'
import { FaInfoCircle } from 'react-icons/fa'
import TextLoader from '../../../../../components/common/TextLoader'
import { useTranslation } from 'react-i18next'

const ArchivioRendicontazioneBilancio = () => {
  const { t } = useTranslation();
  const [gettingBilanci, setGettingBilanci] = useState<boolean>(false)
  const [bilanci, setBilanci] = useState<IBilancioRendicontabile[]>([])

  useEffect(() => {
    setGettingBilanci(true)
    getBilanciRendicontazione()
      .then((res) => setBilanci(res.data.data))
      .finally(() => setGettingBilanci(false))
  }, [])

  return (
    <div>
      <div className="flex flex-col gap-2">
        {
          gettingBilanci ?
            <div className="flex flex-col gap-4">
              <TextLoader />
              <TextLoader />
              <TextLoader />
            </div>
          :
            <>
              {
                bilanci &&
                <>
                  {bilanci.length > 0 ?
                    bilanci.map((bilancio: IBilancioRendicontabile) => <BilancioTile bilancio={bilancio} />)
                    :
                    <div className="text-center font-xl my-4 flex flex-col items-center justify-center gap-4 opacity-70">
                      <FaInfoCircle size={40} />
                      <p className="text-xl">{t('ModuloFattoriEsg.ArchivioRendicontazioneBilancio.nonHaiAncoraAcesso') }</p>
                    </div>
                  }
                </>
              }
            </>
        }
      </div>
    </div>
  )
}

export default ArchivioRendicontazioneBilancio;

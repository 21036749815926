import { observable, action, makeAutoObservable, runInAction } from 'mobx'

import {
  getModuli,
  getModulo,
  ordinaModuli,
  creaModulo,
  modificaModulo,
  eliminaModulo
} from '../rest/gestioneModuli'

import { IModulo } from '../../../types'

export class GestioneModuliStore {

  moduli: IModulo[] = []

  creatingModulo: boolean = false
  creatingModuloError: string = ''

  editingModulo: boolean = false
  modificaModuloError: string = ''
  moduloModificato: boolean = false

  deletingModulo: boolean = false
  eliminaModuloError: string = ''
  moduloEliminato: boolean = false

  orderingModuli: boolean = false
  orderingModuliError: string = ''
  moduliOrdinati: boolean = false

  constructor() {
    makeAutoObservable(this, {

      moduli: observable,
      getModuli: action,

      //Crea modulo
      creaModulo: action,
      creatingModulo: observable,
      creatingModuloError: observable,

      //Modifica modulo
      modificaModulo: action,
      editingModulo: observable,
      modificaModuloError: observable,
      moduloModificato: observable,

      //Elimina modulo
      eliminaModulo: action,
      deletingModulo: observable,
      eliminaModuloError: observable,
      moduloEliminato: observable,

      //Ordina moduli
      ordinaModuli: action,
      orderingModuli: observable,
      orderingModuliError: observable,
      moduliOrdinati: observable
    })
  }

  async getModuli() {
    await getModuli().then(res => {

      runInAction(() => this.moduli = res.data.data)


    }).catch(err => {

      console.log(err)

    })
  }

  async getModulo(codice: string) {
    await getModulo(codice).then(res => {

      runInAction(() => this.moduli = res.data.data)

    }).catch(err => {

      console.log(err)

    })
  }

  async ordinaModuli(moduli: IModulo[]) {

    this.orderingModuli = true

    await ordinaModuli(moduli).then(res => {

      runInAction(() => this.orderingModuliError = '')
      runInAction(() => this.moduliOrdinati = !this.moduliOrdinati)

    }).catch((err) => {

      runInAction(() => this.orderingModuliError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.orderingModuli = false)

    })
  }

  //Crea un modulo
  async creaModulo(modulo: IModulo) {

    this.creatingModulo = true

    await creaModulo(modulo).then(res => {

      runInAction(() => this.creatingModuloError = '')

    }).catch(err => {

      runInAction(() => this.creatingModuloError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.creatingModulo = false)

    })
  }


  async modificaModulo(modulo: IModulo) {

    this.editingModulo = true

    await modificaModulo(modulo).then(res => {

      runInAction(() => this.modificaModuloError = '')

    }).catch(err => {

      runInAction(() => this.modificaModuloError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingModulo = false)

      runInAction(() => this.moduloModificato = !this.moduloModificato)

    })
  }

  async toggleAttivazioneModulo(modulo: IModulo) {

    this.editingModulo = true


    await modificaModulo(modulo).then(res => {

      runInAction(() => this.modificaModuloError = '')

    }).catch(err => {

      runInAction(() => this.modificaModuloError = err.response.data.message ? err.response.data.message : 'Errore generico')

    }).finally(() => {

      runInAction(() => this.editingModulo = false)

      runInAction(() => this.moduloModificato = !this.moduloModificato)

    })
  }

  async eliminaModulo(codice: string) {

    this.deletingModulo = true

    await eliminaModulo(codice).then(res => {

      runInAction(() => this.eliminaModuloError = '')

    }).catch(err => {

      runInAction(() => this.eliminaModuloError = err.response.data.message ? err.response.data.message : 'Errore generico')

      console.log(this.eliminaModuloError)

    }).finally(() => {

      runInAction(() => this.deletingModulo = false)

      runInAction(() => this.moduloEliminato = !this.moduloEliminato)
    }
    )
  }

}
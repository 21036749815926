import React, { useState, useEffect } from 'react'
import { useModuloRendicontazioneBilanciStores } from '../../context/useModuloRendicontazioneBilanciStores';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaDotCircle, FaPlus } from 'react-icons/fa';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

const FiltroRicerca = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const { t } = useTranslation()

    const { watch, register } = useForm()

    useEffect(() => {

        if(watch('ricerca')) {
            rendicontazione_bilanci.applicaFiltriRicerca(watch('ricerca'))
        } else {
            rendicontazione_bilanci.applicaFiltriRicerca('')
        }

    }, [watch('ricerca')])
    
    return (
        <div className="flex flex-col gap-2">
            <p>
                Cerca fattori per nome e descrizione
            </p>
            <div className="form">
                <input placeholder={t('common.cerca')} type="text" {...register('ricerca')} />
            </div>
        </div>
    )
})

export default FiltroRicerca;
import React from 'react'

//Data
import { observer } from 'mobx-react-lite'

//Components
import { Link } from 'react-router-dom'
import { Outlet, Navigate } from 'react-router-dom'
import { FaExternalLinkAlt } from 'react-icons/fa'
import CambioLingua from '../../components/common/CambioLingua'

export const Unlogged = observer(() => {

  const styles = {
    wrapper: "w-screen min-h-screen md:h-screen flex flex-col md:flex-row text-text",
    content: "overflow-y-auto h-full bg-white w-full md:w-[550px] lg:w-[700px] flex flex-col justify-between p-4 sm:p-6 gap-5 border-r border-lightgray-dd",
    imageWrapper: "w-full md:max-h-full md:h-full bg-accent dark:bg-dark-accent overflow-hidden items-center justify-center flex relative grow",
    image: "min-w-full min-h-full object-cover",
    links: "absolute top-0 md:top-auto md:bottom-0 w-full p-4 bg-lightgray-d border-t border-b md:border-b-0 border-lightgray-dd",
    linksList: "flex gap-2 items-center justify-center md:justify-start w-full"
  }

  return (
    <>
      {/* Redirect all'applicazione quando l'utente è loggato */}
      {localStorage.getItem('utente') && <Navigate to="/app" />}
      <div className={styles.wrapper}>

        <div className={styles.content}>
          <div className="flex flex-col gap-4">
            <Link to='/'>
              <img className="w-[50px]" src="ipe-logo.svg" alt="" />
            </Link>
            <Outlet />
          </div>

          <div>
            <CambioLingua />
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <img className={styles.image} src="imgs/background.jpg" alt="" />

          <div className={styles.links}>
            <ul className={styles.linksList}>
              <li>
                <Link className="text-text text-xs md:text-sm" to="contatti">
                  Contatti
                </Link>
              </li>
              <li>
                <Link className="text-text text-xs md:text-sm" to="informativa-dati-termini-uso">
                  Informativa Dati e Termini d'uso
                </Link>
              </li>
              <li className="relative top-[2px] md:top-[1px]">
                <a href="https://www.eambientegroup.com" target="_blank" rel="noreferrer" className="flex gap-1 items-center dark:a-dark">
                  <span className="text-xs md:text-sm">IMQ eAmbiente</span>
                  <FaExternalLinkAlt size={10} />
                </a>
              </li>
            </ul>
          </div>

        </div>

      </div>
    </>
  )
})
import React from "react"
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from "../../hooks/useStores"
import dayjs from "dayjs"
import { IRichiesta } from "../../types";

//Components
import CreaUtenteForm from "./CreaUtenteForm"
import RifiutaUtenteForm from "./RifiutaUtenteForm";

const TileRichiestaUtente: React.FC<{ richiesta: IRichiesta }> = ({ richiesta }) => {
  const { ui } = useStores();
  const { t } = useTranslation();

  const styles = {
    tile: "bg-accent dark:bg-dark-accent p-4 text-white rounded-sm shadow-xl flex flex-col justify-between"
  }

  return (
    <div className={styles.tile}>
      <div>
        <p className="text-xs pb-3 w-8">{t('ModuloGestioneUtenti.TileRichiestaUtente.richiestaRicevuta')} {dayjs(richiesta.ricevuta_il).locale('it').format('DD MMMM YYYY')}</p>
        <hr />
        <div className="my-4">
          <h4 className="font-bold text-lg">{richiesta.nome} {richiesta.cognome}</h4>
          <p>{richiesta.azienda}</p>
          <p>{richiesta.email}</p>
          <p>{richiesta.telefono}</p>
          <p className="mt-2">Lingua richiesta: {richiesta.lingua} <span className={`scale-[0.8] fi fi-${richiesta.lingua}`} /></p>
        </div>
      </div>
      <div className="justify-self-end">

        <button
          className="block w-full p-2 rounded-sm bg-white text-accent font-bold dark:bg-white"
          onClick={() => ui.mountAsideModal(<CreaUtenteForm richiesta={richiesta} />)}
        >
         {t('ModuloGestioneUtenti.TileRichiestaUtente.acettataRichiesta')}
        </button>

        <button
          className="block w-full p-2 text-white"
          onClick={() => ui.mountAsideModal(<RifiutaUtenteForm richiesta={richiesta} />)}
        >
          {t('ModuloGestioneUtenti.TileRichiestaUtente.riffutataRichiesta')}
        </button>

      </div>
    </div>
  )
}

export default TileRichiestaUtente;
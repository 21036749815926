export const en_modulo_gestione_utenti = {
    SchedaUtente: {
        titoloAdmin: 'Admin user',
        schedaUtente: 'User card',
        accountCreato: 'Account created on',
        gruppiUtenti: 'Groups you belong to',
        nessunoGruppiUtenti: ' You don\'t belong to any group',
        moduliUtenti: 'Modules you have access to',
        nessunoModuli: 'You don\'t have acccess to any module',
    },

    FormAggiungiUtentiGruppo: {
        aggiungiUtentiGruppo: 'Add users to the group',
        testoAggiungiUtenti: 'A group can contain multiple users. Each group can be associated with permissions to let users act on different modules of the application.',
        utenti: 'Users',
        selezioneUtenti: 'Select users',
        tuttiSelezionati: 'All users selected',
        selezioneTutti: 'Select all',
    },

    FormCreaGruppo: {
        creaNuovoGruppo: 'Create new group',
        messaggioNomeLimitizione: 'Cannot contain spaces, capital letters or special characters',
        gruppoCreatoSuccesso: 'Group successfully created',
        creaGruppo: 'Create group',
    },

    GruppoContextMenu: {
        gruppoEliminatoSucesso: 'Group successfully deleted',
        modificaGruppo: 'Edit group',
        eliminaGruppo: 'Delete group',
        mesaggioElimina: 'Are you sure you want to delete the group',
        messaggioCompleto: 'The users in this group will not be deleted, but they will lose the privileges associated with the group.',
        testoEliminaButtone: 'Delete the group',
    },

    GruppoTile: {
        utente: 'users',
        collegaUtente: 'Add new user',
    },

    ModificaGruppo: {
        testoModifica: 'Edit the group',
        placeHolder: 'es: group_1',
    },

    FormCreaUtenti: {
        creaUnUtente: 'Create an user ',
        selezioneGruppi: 'Select groups',
        tuttiGruppiSelezionati: 'All the groups selected',
        utenteCreatoSucesso: 'User succesfully created',
        creaUtente: 'Create user',
    },

    FormModificaUtente: {
        modificaUtente: 'Edit user',
        modificaUtenteText: 'Editing user',
        testoModifica: 'you will be able to change his personal data, the groups he belongs to and consequently the privileges he will get within the platform.',
        disabilitaTemporaneamente: 'Temporarily disable',
    },

    PannelloRichieste: {
        richiesteAperte: 'Pending requests',
        nessunoRichiesta: 'No pending request',
        richiesteGestite: 'Requests already managed',
    },

    PannelloUtenti: {
        utentiSenzaGruppi: 'Users without group',
        utentiTrovati: 'users found',
        nessunoGruppoTrovato: 'No group found',
        aggiungiNuovoUtente: 'Add new user',
    },

    TileRichiestaUtente: {
        richiestaRicevuta: 'Request received on',
        acettataRichiesta: 'Accept request',
        riffutataRichiesta: 'Reject request',
    },

    FormRifiutaUtente: {
        rifiutaRichesta: 'Reject request for the user',
        rifiutaQuestaRichesta: 'Reject this request.',
        ragioneDelRifiuta: 'Reason for rejection',
        messaggioRagione: 'Specify the reason for rejection',
        statoRifuitato: 'The user has been rejected',
        confirmaRifuitato: 'Confirm rejection',
    },

    UtenteContextMenu: {
        utenteEliminatoSucesso: 'User successfully deleted',
        modificaUtente: 'Edit user',
        eliminaMessagio: 'Are you sure you want to delete the user',
        eliminaTestoMessagio: 'He will lose his account permanently and will no longer be able to log in. However, all the data he has entered into the platform will not be deleted.',
        eliminaUtente: 'Delete the user',
        eliminaUtenteButtone: 'Delete user',
    },

    TileUtente: {
        utenteDisabilitato: 'User temporarily disabled',
        registroIl: 'Signed up on',
        emailVerificata: 'E-mail verified',
        emailNonVerificat: 'E-mail not verified',
        passwordImpostata: 'Password set',
        passwordNonImpostata: 'Password not set',
    },
}
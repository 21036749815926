import React from 'react'
import { useTranslation } from 'react-i18next'

import { useStores } from '../../../hooks/useStores'

import { FaPlus } from 'react-icons/fa'
import CreaGruppoForm from './CreaGruppoForm'

export default function AggiungiGruppo() {
  const { t } = useTranslation();

  const { ui } = useStores()

  const styles = {
    wrapper: "w-full p-4 border border-accent text-accent dark:text-dark-accent flex flex-col items-center gap-2 rounded-sm text-center mt-5 cursor-pointer hover:opacity-70 dark:bg-darkgray-dd",
  }

  return (
    <div className={styles.wrapper} onClick={() => ui.mountAsideModal(<CreaGruppoForm />)}>
      <FaPlus size={30} />
      {t('common.aggiungiGruppo')} 
    </div>
  )
}
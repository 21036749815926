import React, { SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm, Controller } from 'react-hook-form';
import { useStores } from '../../../hooks/useStores';
import { observer } from 'mobx-react-lite';
import { IGruppo, IUtenteForm } from '../../../types';
import { getUtenti } from '../rest/gestioneUtenti';
import { MultiSelect } from "react-multi-select-component";
import { config } from '../../../config';

//Components
import TestoErrore from '../../../components/common/TestoErrore';
import ErrorBox from '../../../components/common/ErrorBox';
import InfoBox from '../../../components/common/InfoBox';
import SpinnerButton from '../../../components/common/SpinnerButton';
import InputField from '../../../components/common/form/InputField';

const CreaGruppoForm = observer(() => {
  const { t } = useTranslation();

  const { gestioneUtenti } = useStores();

  //Istanzia Hook Form
  const { watch, register, handleSubmit, control, formState: { errors, isSubmitSuccessful } } = useForm();

  //Gestisci submit
  const onSubmit = () => {
    gestioneUtenti.creaGruppo(watch() as IGruppo)
  }

  //Lista tutti gli utenti
  const [utenti, setUtenti] = useState<any>([])

  //Lista utenti selezionati
  const [utentiSelezionati, setUtentiSelezionati] = useState([]);

  //Ottiene lista utenti
  useEffect(() => {
    getUtenti().then(res => {
      setUtenti(res.data.data)
    })
  }, [])

  //Ristruttura dati per label MultiSelect
  utenti.forEach((utente: IUtenteForm) => {
    utente.value = utente.id
    utente.label = `${utente.nome} ${utente.cognome}`
  })

  return (
    <div>
      <div className="mb-4">
        <h2 className="text-2xl mb-2">{t('ModuloGestioneUtenti.FormCreaGruppo.creaNuovoGruppo')}</h2>
        <p className="text-text-light">{t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.testoAggiungiUtenti')}</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form">
          <label htmlFor="">{t('common.nome')}</label>
          <input autoFocus {...register("nome", { required: t('common.fornisciNome') })} placeholder={t('common.nome')} type="text" />

          {errors.nome && <TestoErrore errore={errors.nome.message} />}
        </div>

        <div className="form">
          <label htmlFor="">{t('common.descrizione')}</label>
          <input {...register("descrizione", { required: t('common.fornisciDescrizione') })} placeholder={t('common.descrizione')} type="text" />

          {errors.descrizione && <TestoErrore errore={errors.descrizione.message} />}
        </div>

        <InputField
          label={t('common.codice')}
          classi="codice"
          placeholder="es: gruppo_1"
          type="text"
          error={errors.codice}
          form={register("codice", {
            required: t('common.fornisciCodice'), pattern: {
              value: config.REGEX_SNAKECASE,
              message: t('ModuloGestioneUtenti.FormCreaGruppo.messaggioNomeLimitizione')
            }
          })}
        />


        <div className="form">
          <label htmlFor="">{t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.utenti')}</label>
          <Controller
            control={control}
            name="utenti"
            render={({
              field: { onChange },
            }) => (
              <MultiSelect
                className="dark:text-black"
                options={utenti}
                value={utentiSelezionati}
                onChange={(e: SetStateAction<never[]>) => { setUtentiSelezionati(e); onChange(e) }}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneTutti'),
                  allItemsAreSelected: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.tuttiSelezionati'),
                  selectAll: t('ModuloGestioneUtenti.FormAggiungiUtentiGruppo.selezioneTutti'),
                  search: t('common.cerca'),
                }}
              />
            )}
          />
        </div>

        {
          isSubmitSuccessful && !gestioneUtenti.creatingGruppoError && !gestioneUtenti.creatingGruppo
            ?
            <div className="mb-2">
              <InfoBox messaggio={t('ModuloGestioneUtenti.FormCreaGruppo.gruppoCreatoSuccesso')} />
            </div>
            :
            <div className="form">
              {!gestioneUtenti.creatingGruppo ? <input type="submit" value={t('ModuloGestioneUtenti.FormCreaGruppo.creaGruppo')} /> : <SpinnerButton />}
            </div>
        }

        {
          gestioneUtenti.creatingGruppoError && (
            <div className="mb-2">
              <ErrorBox errore={gestioneUtenti.creatingGruppoError.toString()} />
            </div>
          )
        }

      </form>
    </div>
  )
})

export default CreaGruppoForm;
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

//Components
import { FaFilter } from 'react-icons/fa'
import TileUtente from './UtenteTile'
import CreaUtenteForm from './CreaUtenteForm'
import GruppoTile from './gruppi/GruppoTile'
import AggiungiGruppo from './gruppi/AggiungiGruppo'
import AggiungiButton from '../../components/common/AggiungiButton'

export const PannelloUtenti = observer(() => {
    const { t } = useTranslation();

    const [filters, setFilters] = useState(false);

    const styles = {
        cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
    }

    const privilegio = useOttieniPrivilegioModulo();
    const { gestioneUtenti, ui } = useStores();

    //Get users
    useEffect(() => {
        gestioneUtenti.getUtenti();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        gestioneUtenti.creatingUtente,
        gestioneUtenti.utenteModificato,
        gestioneUtenti.utenteEliminato,
        gestioneUtenti.creatingGruppo,
        gestioneUtenti.gruppoModificato,
        gestioneUtenti.gruppoEliminato
    ])

    //Get groups
    useEffect(() => {
        gestioneUtenti.getGruppi();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        gestioneUtenti.creatingUtente,
        gestioneUtenti.utenteModificato,
        gestioneUtenti.utenteEliminato,
        gestioneUtenti.creatingGruppo,
        gestioneUtenti.gruppoModificato,
        gestioneUtenti.gruppoEliminato
    ])

    return (
        <div>
            {gestioneUtenti.utenti.length > 0 ?
                <>
                    <div className="flex items-center justify-between flex-col md:flex-row my-5">

                        <h2 className="flex items-center gap-3">
                            <span className="text-2xl">{t('ModuloGestioneUtenti.PannelloUtenti.utentiSenzaGruppi')}</span>
                            <FaFilter className="opacity-70 hover:opacity-100 cursor-pointer" onClick={() => setFilters(!filters)} />
                        </h2>

                        <p>{gestioneUtenti.utenti.length} {t('ModuloGestioneUtenti.PannelloUtenti.utentiTrovati')}</p>
                    </div>

                    <div className={styles.cardsWrapper}>
                        {gestioneUtenti.utenti.map((utente, i) => <TileUtente key={i} utente={utente} />)}

                        {privilegio >= 2 &&
                            <div onClick={() => ui.mountAsideModal(<CreaUtenteForm />)}>
                                <AggiungiButton testo={t('ModuloGestioneUtenti.PannelloUtenti.aggiungiNuovoUtente')} />
                            </div>
                        }
                    </div>
                </>
                :
                <div>
                    {privilegio >= 2 &&
                        <div onClick={() => ui.mountAsideModal(<CreaUtenteForm />)}>
                            <AggiungiButton testo={t('ModuloGestioneUtenti.PannelloUtenti.aggiungiNuovoUtente')} />
                        </div>
                    }
                </div>
            }

            <h2 className="flex items-center gap-3 mt-6">
                <span className="text-2xl">{t('common.gruppi')}</span>
            </h2>

            {gestioneUtenti.gruppi.length >= 1
                ?
                <div className="flex flex-col gap-5 mt-5">
                    {gestioneUtenti.gruppi.map((gruppo, i) => <GruppoTile key={gruppo.codice} gruppo={gruppo} />)}
                </div>
                :
                <div>
                    <p className="text-center text-2xl py-5 opacity-70">{t('ModuloGestioneUtenti.PannelloUtenti.nessunoGruppoTrovato')}</p>
                </div>
            }

            {privilegio >= 2 && <AggiungiGruppo />}

        </div>
    )
})

export const it_modulo_gestione_moduli = {

    Archivio: {
        nessunoModulo: 'Nessun modulo presente',
        nuovoModulo: 'Aggiungi nuovo modulo',
        riordinaModuli: 'Riordina moduli',
        passaGestionePrivilegi: 'Passa a gestione privilegi',
    },

    ContextMenu: {
        modificaModulo: 'Modifica modulo',
        modulo: 'modulo',
        attiva: 'Attiva',
        disattiva: 'Disattiva',
        modificaPremessi: 'Modifica permessi modulo',
        eliminaModulo: 'Elimina modulo',
    },

    Form: {
        modificaModulo: 'Modifica il modulo',
        testoModifica: 'Modifica i dati del modulo',
        testModificaCompleto: 'associa dei gruppi per consentire agli utenti che ne fanno parte di accedere alle funzionalità.',
        testoCrea: 'Crea un nuovo modulo e associalo ai gruppi per consentire agli utenti che ne fanno parte di accedere alle funzionalità.',
        testoPlaceholderCodice: 'es: modulo_1',
        testoPlaceholderNome: 'Nome del modulo',
        testoPlaceholderDescrizione: 'Descrizione del modulo',
        icona: 'Icona',
        selezioneIcona: 'Seleziona un\'icona',
        moduloGenitore: 'Modulo genitore',
        visibileNavBar: 'Visibile nella navbar',
        visibileHeader: 'Visibile nell\'header',
        disattivaModulo: 'Disattiva modulo',
        messaggioSalvataSucesso: 'Modifiche salvate con successo',
        messagioCreatoSucesso: 'Modulo creato con successo',
        creaModulo: 'Crea modulo',
    },

    Tile: {
        privileiGenitore: 'Privilegio ereditato dal modulo genitore',
        privilegiDisattivi: 'Privilegio disattivato',
    },

    FormPrivilegi: {
        testoAttribuisci: 'Attribuisci dei permessi al modulo ',
        testoDescrizione: 'Puoi stabilire quali gruppi avranno accesso al modulo e in quale misura.',
        tuttiGruppi: 'Tutti i gruppi selezionati',
        unoGruppo: 'Seleziona un gruppo e aggiungilo per concedere l\'accesso al modulo',
        selezionaGruppo: 'Seleziona gruppo',
        aggiungiGruppi: 'Aggiungi il gruppo',
        privilegi: 'Privilegi',
        attivaPrivilegi: 'Attiva privilegio',
        salavaPrivilegi: 'Salva privilegi',
        modificaPrivilegi: 'Modifiche salvate con successo',
    },

    Ordinamento: {
        riordinaVisualizzazioni: 'Riordina l\'ordine di visualizzazione dei moduli in tutta la piattaforma',
        riordinaNavbar: 'Riordina i moduli nella Navbar',
        testoNavBar: ' Puoi modificare l\'ordinamento in cui i moduli appaiono nel menu della navbar',
        spostaNelMenuUtente: 'Sposta nel menu Utente',
        riordinaNelMenuUtente: 'Riordina i moduli nel menu Utente',
        testoMenuUtente: ' Puoi modificare l\'ordinamento in cui i moduli appaiono nel menu dell\'utente',
        spostaNelNavBar: 'Sposta nella Navbar',
        spostaNeiModuliNonVisibile: 'Sposta nei moduli non visibili',
        moduliNonVisibili: 'Moduli non visibili',
        testModuliNonVisibile: ' Questi sono i moduli non accessibili dalla navbar o dal menu utente',
    },
}
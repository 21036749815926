import React, { useEffect } from 'react'

//Data
import { observer } from 'mobx-react'
import { useLocation, useParams } from 'react-router-dom'
import { useModuloRendicontazioneBilanciStores } from '../context/useModuloRendicontazioneBilanciStores'

//Components
import ListaFiltri from '../components/filtri/ListaFiltri'
import HeaderRendicontazioneBilancio from '../components/HeaderRendicontazioneBilancio'
import ListaFattoriRendicontabili from '../components/ListaFattoriRendicontabili'
import ListaTagFiltri from '../components/filtri/ListaTagFiltri'
import Spinner from '../../../../../components/common/Spinner'
import { useTranslation } from 'react-i18next'

const SchedaRendicontazioneBilancioBusinessUnit = observer(() => {

  const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
  const { bilancio_anno, business_unit_nome } = useParams()
  const { t } = useTranslation()
  const location = useLocation();

  useEffect(() => {

    if (!rendicontazione_bilanci.bilancio) {
      rendicontazione_bilanci.settaBilancio(bilancio_anno as string)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //Aggiorna bu attiva
  useEffect(() => {
    rendicontazione_bilanci.settaBusinessUnitAttiva(bilancio_anno as string, business_unit_nome as string)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])


  return (
    <div className="grow">

      <HeaderRendicontazioneBilancio />

      <div className="flex flex-col lg:flex-row gap-2 items-start">

        <div className="w-full lg:w-[300px] lg:min-w-[300px] lg:max-w-[300px] shadow-xl bg-white dark:bg-darkgray-d relative">
          <div className="sticky mt-0 w-full">
            <ListaFiltri />
          </div>
        </div>

        <div className="flex flex-col gap-2 grow">

          <ListaTagFiltri />

          {rendicontazione_bilanci.gettingBusinessUnit ?
            <div className="flex flex-col gap-2 items-center justify-center py-4">
              <Spinner colore='blue' />
              {t('ModuloFattoriEsg.SchedaRendicontazioneBilancio.caricandoFattoriESG')}
            </div>
            :
            <ListaFattoriRendicontabili />
          }
        </div>

      </div>

    </div>
  )
})

export default SchedaRendicontazioneBilancioBusinessUnit;

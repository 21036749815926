import React from 'react'

//Data
import { observer } from "mobx-react"
import { useStores } from "../../hooks/useStores"
import { IUtente } from "../../types"
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'
import { useTranslation } from 'react-i18next'

//Components
import Dialogue from "../../components/common/Dialogue"
import ModificaUtenteForm from "./ModificaUtenteForm"

const UtenteContextMenu: React.FC<{ utente: IUtente }> = observer(({ utente }) => {
    const { t } = useTranslation();

    const privilegio = useOttieniPrivilegioModulo();
    const { ui, gestioneUtenti } = useStores();

    const handleEliminaUtente = () => {

        gestioneUtenti.eliminaUtente(utente.id).then(() => {

            if (gestioneUtenti.eliminaUtenteError) {

                ui.mountDialogue(<Dialogue testo={gestioneUtenti.eliminaUtenteError} />)

            } else {

                ui.mountDialogue(<Dialogue testo={t('ModuloGestioneUtenti.UtenteContextMenu.utenteEliminatoSucesso')} />)

            }

        });

    }

    return (
        <div className="absolute shadow-lg border border-lightgray-dd bg-white dark:bg-black p-3 right-0 dark:border-0">
            <ul className="whitespace-nowrap flex flex-col gap-2">
                {privilegio >= 3 && <li onClick={() => ui.mountAsideModal(<ModificaUtenteForm utente={utente} />)}>{t('ModuloGestioneUtenti.UtenteContextMenu.modificaUtente')}</li>}
                {privilegio >= 4 &&
                    <li
                        className="text-red dark:text-dark-red"
                        onClick={() => ui.mountDialogue(
                            <Dialogue
                                testo={`${t('ModuloGestioneUtenti.UtenteContextMenu.eliminaMessagio')} ${utente.nome}? ${t('ModuloGestioneUtenti.UtenteContextMenu.eliminaTestoMessagio')}.`}
                                primaryCta={`${t('ModuloGestioneUtenti.UtenteContextMenu.eliminaUtente')} ${utente.nome}`}
                                primaryCtaColor="bg-red dark:bg-dark-red"
                                secondaryCta={t('common.annulla')}
                                secondaryCtaColor="bg-white dark:bg-darkgray-dd !text-text dark:!text-dark-text"
                                onConfirm={() => handleEliminaUtente()}
                            />)}
                    >
                        {t('ModuloGestioneUtenti.UtenteContextMenu.eliminaUtenteButtone')}
                    </li>
                }
            </ul>
        </div>
    )
})

export default UtenteContextMenu
import { IAttivita } from '../../types';

import { creaStringStandardPerAttivita } from '../../hooks/attivita/creaStringStandardPerAttivita';

export default function factoryAttivitaBusinessUnit(attivita: IAttivita) {

    let codiceInterpretato = '';

    switch (attivita.route) {

        case 'BusinessUnit.crea_business_unit':
            codiceInterpretato = `Hai <span class="azione bg-accent dark:bg-dark-accent">creato</span> la <a target="_blank" href="/app/modulo/business_unit/${JSON.parse(attivita.request_corpo)?.nome}">business unit ${JSON.parse(attivita.request_corpo)?.nome}</a>`
            break;

        case 'BusinessUnit.modifica_business_unit':
            codiceInterpretato = `Hai <span class="azione bg-orange dark:bg-dark-orange">modificato</span> la <a target="_blank" href="/app/modulo/business_unit/${JSON.parse(attivita.request_corpo)?.nome}">business unit ${JSON.parse(attivita.request_corpo)?.nome}</a>`
            break;

        case 'BusinessUnit.elimina_business_unit':
            codiceInterpretato = `Hai <span class="azione bg-red dark:bg-dark-red">eliminato</span> la <a target="_blank" href="/app/modulo/business_unit/${JSON.parse(attivita.request_corpo)?.nome}">business unit ${JSON.parse(attivita.request_corpo)?.nome}</a>`
            break;

        case 'BusinessUnit.attribuisci_business_unit_ruoli':
            codiceInterpretato = attribuisci_business_unit_ruoli(attivita)
            break;

        default:
            codiceInterpretato = creaStringStandardPerAttivita(attivita)
    }

    return codiceInterpretato;

}

const attribuisci_business_unit_ruoli = (attivita: IAttivita) => {

    let stringa = `Hai `
    stringa += `<span class="azione bg-accent dark:bg-dark-accent">attribuito</span> `
    stringa += `un ruolo `
    stringa += `alla <a href="/app/modulo/business_unit/${attivita.uri.split('/')[attivita.uri.split('/').length - 2]}" target="_blank">business_unit ${attivita.uri.split('/')[attivita.uri.split('/').length - 2]}</a>`

    return stringa;
}
import React from 'react'

//Data
import { useForm } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

//Components
import ErrorBox from '../common/ErrorBox'
import TestoErrore from '../common/TestoErrore'
import SpinnerButton from '../common/SpinnerButton'
import { IUtente } from '../../types'

export const LoginForm = observer(() => {
  const { t, i18n } = useTranslation();

  const { auth } = useStores();

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const onSubmit = () => {
    auth.login(watch() as IUtente, i18n.language)
  }

  return (
    <form id="login-form" onSubmit={handleSubmit(onSubmit)}>

      <div className="form">
        <label htmlFor="">{t('common.eMail')}</label>
        <input id="login-email" {...register("email", { required:t('common.fornisciEmail') })} placeholder={t('common.eMail')} type="email" />
        {errors.email && <TestoErrore errore={errors.email.message} />}
      </div>

      <div className="form">
        <label htmlFor="">{t('common.password')}</label>
        <input id="login-password" {...register("password", { required: t('common.fornisciPassword')})} placeholder={t('common.password')} type="password" />
        {errors.password && <TestoErrore errore={errors.password.message} />}
      </div>

      <div className="form pt-4">
        {!auth.gettingUtente ? <input id="login-submit" type="submit" value={t('common.login')} /> : <SpinnerButton />}
      </div>

      {auth.getUtenteError && <ErrorBox errore={auth.getUtenteError.toString()} />}
    </form>
  )
})
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useForm } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { IModulo } from '../../types'
import { observer } from 'mobx-react-lite'
import { config } from '../../config'
import useListaIcone from '../../hooks/useListaIcone'
import { getAllModuli } from './rest/gestioneModuli'

//Components
import InfoBox from '../../components/common/InfoBox'
import ErrorBox from '../../components/common/ErrorBox'
import SpinnerButton from '../../components/common/SpinnerButton'
import InputField from '../../components/common/form/InputField'
import TestoErrore from '../../components/common/TestoErrore'

const ModuloForm: React.FC<{ modulo?: IModulo }> = observer(({ modulo }) => {
  const { t } = useTranslation();

  const { gestioneModuli } = useStores()

  const { listaIcone } = useListaIcone();

  const [listaModuli, setListaModuli] = useState<IModulo[]>([])

  useEffect(() => {
    getAllModuli().then((res) => {
      setListaModuli(res.data.data)
    })
  }, [])

  //Istanzia Hook Form
  const { watch, register, setValue, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      codice_originale: modulo ? modulo.codice : '',
      codice: modulo ? modulo.codice : '',
      nome: modulo ? modulo.nome : '',
      descrizione: modulo ? modulo.descrizione : '',
      icona: modulo ? modulo.icona : '',
      gruppi: modulo ? modulo.gruppi : '',
      attivo: modulo ? !modulo.attivo : '',
      codice_padre: modulo ? modulo.codice_padre : '',
      visibile_navbar: modulo ? modulo.visibile_navbar : false,
      visibile_header: modulo ? modulo.visibile_header : false,
    }
  })

  //Gestisce submit
  const onSubmit = () => {
    modulo ?

      gestioneModuli.modificaModulo(watch() as IModulo).then(() => {
        setValue('codice_originale', watch('codice'))
      })
      :

      gestioneModuli.creaModulo(watch() as IModulo)

  }

  return (
    <div>

      <div className="mb-4">
        <h2 className="text-2xl mb-2">
          {modulo ?
            `${t('ModuloGestioneModuli.Form.modificaModulo')} ${modulo.nome}`
            :
            t('ModuloGestioneModuli.Form.testoCrea')
          }
        </h2>
        <p className="text-text-light">
          {modulo ?
            `${t('ModuloGestioneModuli.Form.testoModifica')} ${modulo.nome},${t('ModuloGestioneModuli.Form.testModificaCompleto')} `
            :
            t('ModuloGestioneModuli.Form.testoCrea')
          }
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>

        <InputField
          label={t('common.codice')}
          classi="codice"
          placeholder={t('ModuloGestioneModuli.Form.testoPlaceholderCodice')}
          type="text"
          error={errors.codice}
          form={register("codice", {
            required: t('common.fornisciCodice'), pattern: {
              value: config.REGEX_SNAKECASE,
              message: "Non può contenere spazi, lettere maiuscole o caratteri speciali"
            }
          })}
        />

        <InputField
          label={t('common.nome')}
          classi="nome"
          placeholder={t('ModuloGestioneModuli.Form.testoPlaceholderNome')}
          type="text"
          error={errors.nome}
          form={register("nome", { required: t('common.fornisciNome') })}
        />

        <InputField
          label={t('common.descrizione')}
          classi="descrizione"
          placeholder={t('ModuloGestioneModuli.Form.testoPlaceholderDescrizione')}
          type="text"
          error={errors.descrizione}
          form={register("descrizione", { required: t('common.fornisciDescrizione') })}
        />

        <div className="form">
          <label htmlFor="">{t('ModuloGestioneModuli.Form.icona')}</label>
          <select {...register("icona", { required: "Scegli un'icona" })}>
            <option value="" key="0" selected>
              {t('ModuloGestioneModuli.Form.selezioneIcona')}
            </option>
            {
              listaIcone.map((icona) => {
                return <option key={icona} value={icona}>{icona}</option>
              })
            }
          </select>
        </div>
        {/* Errore field */}
        {errors.icona && <TestoErrore errore={errors.icona.message} />}

        <div className="form">
          <label htmlFor="">{t('ModuloGestioneModuli.Form.moduloGenitore')}</label>
          <select {...register("codice_padre")}>

            {
              modulo && modulo.codice_padre ?
                <>
                  <option value="" key="0">
                    {t('common.nessuno')}
                  </option>
                  {
                    listaModuli.filter((modulo_item) => modulo_item.codice === modulo.codice_padre).map((modulo_item) => {
                      return <option selected key={modulo_item.codice} value={modulo_item.codice}>{modulo_item.nome}</option>
                    })
                  }
                  {
                    listaModuli.filter((modulo_item) => modulo_item.codice !== modulo.codice_padre).map((modulo_item) => {
                      return <option key={modulo_item.codice} value={modulo_item.codice}>{modulo_item.nome}</option>
                    })
                  }
                </>
                :
                <>
                  <option value="" key="0" selected>
                    {t('common.nessuno')}
                  </option>
                  {
                    listaModuli.map((modulo_item) => {
                      return <option key={modulo_item.codice} value={modulo_item.codice}>{modulo_item.nome}</option>
                    })
                  }
                </>
            }

          </select>
        </div>

        {/* Errore field */}
        {errors.codice_padre && <TestoErrore errore={errors.codice_padre.message} />}

        {
          !modulo &&
          <>
            <div className="form checkbox">
              <label htmlFor="">{t('ModuloGestioneModuli.Form.visibileNavBar')}</label>
              <input {...register("visibile_navbar")} type="checkbox" />
            </div>

            <div className="form checkbox">
              <label htmlFor="">{t('ModuloGestioneModuli.Form.visibileHeader')}</label>
              <input {...register("visibile_header")} type="checkbox" />
            </div>
          </>
        }

        <div className="form checkbox">
          <label htmlFor="">{t('ModuloGestioneModuli.Form.disattivaModulo')}</label>
          <input {...register("attivo")} type="checkbox" />
        </div>

        <div className="form">

          {/* Submit modifica modulo */}
          {
            modulo &&
            <>
              <div className="form">
                <div>
                  {!gestioneModuli.editingModulo ? <input type="submit" value="Salva modifiche" /> : <SpinnerButton />}
                </div>
              </div>

              {
                isSubmitSuccessful && !gestioneModuli.editingModulo && (

                  gestioneModuli.modificaModuloError
                    ?
                    <div className="my-2">
                      <ErrorBox errore={gestioneModuli.modificaModuloError} />
                    </div>
                    :
                    <div className="my-2">
                      <InfoBox messaggio={t('common.modificaSalvato')} />
                    </div>

                )
              }

            </>
          }

          {/* Submit creazione modulo */}
          {!modulo &&

            <>
              {
                (isSubmitSuccessful && !gestioneModuli.creatingModuloError && !gestioneModuli.creatingModulo) ?
                  <InfoBox messaggio={t('ModuloGestioneModuli.Form.messagioCreatoSucesso')} id={"crea-modulo-success"} />
                  :
                  <div className="form pt-4">
                    {!gestioneModuli.creatingModulo ? <input type="submit" value={t('ModuloGestioneModuli.Form.creaModulo')} id="crea-modulo-submit" /> : <SpinnerButton />}
                  </div>
              }

              {
                gestioneModuli.creatingModuloError &&
                <div className="mb-2">
                  <ErrorBox errore={gestioneModuli.creatingModuloError.toString()} />
                </div>
              }

            </>
          }


        </div>

      </form>
    </div>
  )
})

export default ModuloForm;
import axios from 'axios'
import { config } from '../../../config'
import { IModulo } from '../../../types'

//Riceva lista flat di tutti i moduli
export const getAllModuli = () => {
    return axios.get(`${config.API_URI}moduli/flat`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve la lista di tutti i moduli
export const getModuli = () => {
    return axios.get(`${config.API_URI}moduli`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve informazioni sul singolo modulo e il privilegio dell'utente per quel modulo
export const getModulo = (codice: string) => {
    return axios.get(`${config.API_URI}modulo/${codice}`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}

//Riceve la lista di tutti i moduli a cui ha accesso un utente
export const getModuliUtente = () => {
    return axios.get(`${config.API_URI}user/moduli/flat`, { headers: { authorization: `Bearer ${localStorage.getItem('token')}` } })
}


//Permette di riordinare i moduli 
export const ordinaModuli = (moduli: IModulo[]) => {
    return axios.post(`${config.API_URI}moduli/ordinamento`, { moduli }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Crea un modulo
export const creaModulo = (modulo: IModulo) => {
    return axios.post(`${config.API_URI}modulo`, {
        nome: modulo.nome,
        descrizione: modulo.descrizione,
        codice: modulo.codice,
        icona: modulo.icona,
        attivo: !modulo.attivo,
        codice_padre: modulo.codice_padre,
        visibile_navbar: modulo.visibile_navbar,
        visibile_header: modulo.visibile_header,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Modifica un modulo
export const modificaModulo = (modulo: IModulo) => {
    return axios.put(`${config.API_URI}modulo/${modulo.codice_originale ? modulo.codice_originale : modulo.codice}`, {
        nome: modulo.nome,
        descrizione: modulo.descrizione,
        codice_originale: modulo.codice_originale,
        codice: modulo.codice,
        icona: modulo.icona,
        attivo: !modulo.attivo,
        codice_padre: modulo.codice_padre,
        visibile_navbar: modulo.visibile_navbar,
        visibile_header: modulo.visibile_header,
    }, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}

//Elimina un modulo
export const eliminaModulo = (codice: string) => {
    return axios.delete(`${config.API_URI}modulo/${codice}`, {
        headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
}
import React, { useEffect } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'

//Components
import { PannelloUtenti } from './PannelloUtenti'
import { PannelloRichieste } from './PannelloRichieste'
import useOttieniPrivilegioModulo from '../../hooks/useOttieniPrivilegioModulo'

const GestioneUtenti = observer(() => {

  const privilegio = useOttieniPrivilegioModulo();
  const { gestioneUtenti } = useStores();

  useEffect(() => {
    gestioneUtenti.getRichieste();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>

      {/* Sezione richieste */}
      {gestioneUtenti.richieste.length >= 1 &&
        privilegio >= 4 &&
        <>
          <PannelloRichieste />

          <hr className='border border-lightgray-dd my-7' />
        </>
      }

      {/* Sezione utenti */}
      <div>

        <PannelloUtenti />

      </div>

    </div>
  )
})

export default GestioneUtenti;
import React from 'react'

//Data
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { useStores } from '../../hooks/useStores'
import { ILingua } from '../../types'

//Components
import InputField from '../../components/common/form/InputField'
import SpinnerButton from '../../components/common/SpinnerButton'
import ErrorBox from '../../components/common/ErrorBox'
import InfoBox from '../../components/common/InfoBox'


const LinguaForm: React.FC<{ lingua?: ILingua }> = observer(({ lingua }) => {

    const { gestioneLingue } = useStores()
    const { t } = useTranslation();

    //Istanzia Hook Form
    const { watch, register, setValue, handleSubmit, formState: { errors, isSubmitSuccessful } } = useForm({
        defaultValues: {
            codice: lingua ? lingua.codice : '',
            codice_originale: lingua ? lingua.codice : '',
            nome: lingua ? lingua.nome : '',
            bandiera: lingua ? lingua.bandiera : ''
        }
    })
    const onSubmit = () => {
        lingua ?
            gestioneLingue.modificaLingua(watch() as ILingua).then(() => {
                setValue('codice_originale', watch('codice') as string)
            })
            :
            gestioneLingue.creaLingua(watch() as ILingua)
    }

    return (
        <div>
            <div className="mb-4">
                <h2 className="text-2xl mb-2">
                    {lingua ?
                        `${t('ModuloGestioneLingue.Form.modificaLingua')} ${lingua.codice}`
                        :
                        t('ModuloGestioneLingue.Form.creaLingua')
                    }
                </h2>
                <p className="text-text-light">
                    {lingua ?
                        `${t('ModuloGestioneLingue.Form.modificaLingua')} ${lingua.nome},`
                        :
                        t('ModuloGestioneLingue.Form.creaLingua')
                    }
                </p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>

                <InputField
                    label={t('common.codice')}
                    classi="codice"
                    placeholder={t('ModuloGestioneLingue.Form.placeholderCodice')}
                    type="text"
                    error={errors.codice}
                    form={register("codice", { required: t('common.fornisciCodice') },)}
                />

                <InputField
                    label={t('common.nome')}
                    classi="nome"
                    placeholder={t('common.nome')}
                    type="text"
                    form={register("nome")}
                />

                <InputField
                    label={t('common.bandiera')}
                    classi="bandiera"
                    placeholder={t('common.bandiera')}
                    type="text"
                    form={register("bandiera")}
                />

                <div className="form">

                    {/* Submit modifica lingua */}
                    {lingua &&
                        <>
                            <div className="form">
                                <div>
                                    {!gestioneLingue.editingLingua ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
                                </div>
                            </div>

                            {
                                isSubmitSuccessful && !gestioneLingue.editingLingua && (
                                    gestioneLingue.modificaLinguaError
                                        ?
                                        <div className="my-2">
                                            <ErrorBox errore={gestioneLingue.modificaLinguaError} />
                                        </div>
                                        :
                                        <div className="my-2">
                                            <InfoBox messaggio={t('common.modificaSalvato')} />
                                        </div>
                                )
                            }

                        </>
                    }

                    {/* Submit creazione lingua */}
                    {
                        !lingua &&
                        <>
                            {
                                (isSubmitSuccessful && !gestioneLingue.creatingLinguaError && !gestioneLingue.creatingLingua) ?
                                    <InfoBox messaggio={t('ModuloGestioneLingue.Form.messaggiLinguaCreatoSucesso')} id={"crea-unita_misura-success"} />
                                    :
                                    <div className="form pt-4">
                                        {!gestioneLingue.creatingLingua ? <input type="submit" value={t('ModuloGestioneLingue.Form.creaLingua')} id="crea-unita_misura-submit" /> : <SpinnerButton />}
                                    </div>
                            }

                            {
                                gestioneLingue.creatingLinguaError &&
                                <div className="mb-2">
                                    <ErrorBox errore={gestioneLingue.creatingLinguaError.toString()} />
                                </div>
                            }
                        </>
                    }
                </div>
            </form>
        </div>
    )
})
export default LinguaForm;
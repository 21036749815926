import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { getAttivitaUtente } from '../rest/attivita'
import { IAttivita } from '../types'

export class AttivitaStore {
    listaAttivita: IAttivita[] = []
    gettingAttivita: boolean = false
    limiteRaggiunto: boolean = false

    filtri: {
        'moduli': string[],
        'metodi': string[]
    } = {
            'moduli': [],
            'metodi': []
        }

    stringaFiltri: string = ''

    constructor() {
        makeAutoObservable(this, {
            listaAttivita: observable,
            gettingAttivita: observable,
            filtri: observable,
            stringaFiltri: observable,

            getListaAttivita: action,
            ripristinaListaAttivita: action,
            attribuisciFiltri: action,
            costruisciStringaFiltri: action,
            ripristinaFiltri: action
        })
    }

    getListaAttivita = async (offset: number, limit: number, filtri?: string) => {

        this.gettingAttivita = true

        getAttivitaUtente(offset, limit, filtri).then((res) => {

            //Aggiunge risultato alla lista delle attività già esistenti
            runInAction(() => this.listaAttivita = [...this.listaAttivita, ...res.data.data])

            if (limit) {
                if (res.data.data.length < limit) {
                    runInAction(() => this.limiteRaggiunto = true)
                }
            }

        }).finally(() => {

            runInAction(() => this.gettingAttivita = false)

        })
    }

    ripristinaListaAttivita = async () => {
        this.listaAttivita = []
        this.limiteRaggiunto = false
    }

    attribuisciFiltri = async (tipoFiltro: 'metodo' | 'modulo', stringa: string) => {
        if (tipoFiltro === 'modulo') {
            if (this.filtri.moduli.includes(stringa)) {
                this.filtri.moduli = this.filtri.moduli.filter((item) => item !== stringa).map((item) => item)
            } else {
                this.filtri.moduli.push(stringa)
            }
        }

        if (tipoFiltro === 'metodo') {
            if (this.filtri.metodi.includes(stringa)) {
                this.filtri.metodi = this.filtri.metodi.filter((item) => item !== stringa).map((item) => item)
            } else {
                this.filtri.metodi.push(stringa)
            }
        }

        this.costruisciStringaFiltri()

    }

    costruisciStringaFiltri = () => {

        this.stringaFiltri = ''

        if (this.filtri.metodi.length > 0) {
            this.stringaFiltri += `&metodi=${this.filtri.metodi.map(metodo => `${metodo}`)}`
        }

        if (this.filtri.moduli.length > 0) {
            this.stringaFiltri += `&moduli=${this.filtri.moduli.map(modulo => `${modulo}`)}`
        }

    }

    ripristinaFiltri = () => {
        this.filtri = {
            'moduli': [],
            'metodi': []
        }
        this.stringaFiltri = ''
    }
}
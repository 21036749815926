import { IAttivita } from '../../types'

export const creaStringStandardPerAttivita = (attivita: IAttivita) => {
    let stringaStandard = 'Impossibile recuperare l\'attività'

    let articolo = 'un'

    if (attivita.route.split('.')[0][attivita.route.split('.')[0].length - 1] === 'a') {
        articolo = 'una'
    }

    if (attivita.metodo && attivita.uri) {
        let azione = ''

        switch (attivita.metodo) {
            case ('POST'):
                azione = `<span class="azione bg-accent dark:bg-dark-accent">creato</span>`
                break;

            case ('DELETE'):
                azione = `<span class="azione bg-red dark:bg-dark-red">eliminato</span>`
                break;

            case ('PUT'):
                azione = '<span class="azione bg-orange dark:bg-dark-orange">modificato</span>'
                break;

            case ('GET'):
                azione = 'visualizzato'
                break;
        }

        stringaStandard = `Hai ${azione} ${articolo} ${attivita.route.split('.')[0]}`
    }

    return stringaStandard
}
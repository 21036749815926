import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { IBilancio, IBilancioRendicontabile } from '../../types'

//Components
import { FaEllipsisV, FaLink } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import BilancioContextMenu from './BilancioContextMenu'
import useOttieniPrivilegioModulo from '../../../../hooks/useOttieniPrivilegioModulo'
import { useStores } from '../../../../hooks/useStores'

const BilancioTile: React.FC<{ bilancio: IBilancio | IBilancioRendicontabile, modifica?: boolean }> = ({ bilancio, modifica = true }) => {

  const privilegio = useOttieniPrivilegioModulo()
  const { t } = useTranslation();
  
  const [contextMenu, setContextMenu] = React.useState(false)

  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  const { moduli } = useStores()

  return (
    <div className="bg-white dark:bg-darkgray-dd shadow-xl p-5 rounded-sm">
      <div className="flex items-center justify-between">
        <Link to={modifica ? `/app/modulo/${moduli.moduloAttivo?.codice}/${bilancio.anno}` : `/app/modulo/bilanci/${bilancio.anno}`} className="flex items-center gap-2">
          <FaLink />
          <h2 className="font-bold hover:underline">
            <span className="text-xl">{t('ModuloFattoriEsg.BilancioTile.bilancioDiSostenibilita')} </span>
            <span className="text-accent dark:text-dark-accent text-xl">
              {bilancio.anno}
            </span>
          </h2>
        </Link>


        {privilegio >= 3 && modifica &&
          <div className="cursor-pointer relative p-1" onClick={() => toggleContextMenu()}>
            <FaEllipsisV className="hover:opacity-70" />

            {contextMenu && <BilancioContextMenu bilancio={bilancio as IBilancio} />}

          </div>
        }

      </div>
      <hr className="my-2 border border-lightgray-d" />
      <p>{bilancio.descrizione}</p>
    </div>
  )
}

export default BilancioTile;

import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from "../../../hooks/useStores"
import { observer } from "mobx-react-lite";
import { getUtentiGruppo } from "../rest/gestioneGruppi"
import { IGruppo } from "../../../types";
import useOttieniPrivilegioModulo from "../../../hooks/useOttieniPrivilegioModulo";

//Components
import { FaAngleDown, FaAngleUp, FaEllipsisV } from "react-icons/fa"
import GruppoContextMenu from "./GruppoContextMenu"
import UtenteTile from "../UtenteTile"
import AggiungiButton from "../../../components/common/AggiungiButton"
import AggiungiUtentiGruppoForm from "./AggiungiUtentiGruppoForm";

const GruppoTile: React.FC<{ gruppo: IGruppo }> = observer(({ gruppo }) => {
  const { t } = useTranslation();

  const privilegio = useOttieniPrivilegioModulo()

  const { ui, gestioneUtenti } = useStores()

  const [aperto, setAperto] = useState(false)

  const [contextMenu, setContextMenu] = useState(false);

  const toggleContextMenu = () => {
    //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
    setTimeout(() => {
      setContextMenu(!contextMenu)
    }, 1)
  }

  document.addEventListener('click', () => {
    if (contextMenu) {
      setContextMenu(false)
    }
  }, { once: true })

  const styles = {
    wrapper: "bg-white dark:bg-darkgray-dd shadow-xl w-full rounded-sm",
    header: "flex items-center justify-between p-4",
    body: "px-4 pb-4 bg-lightgray dark:bg-darkgray-d",
    button: "cursor-pointer p-1 box-content hover:bg-lightgray dark:hover:bg-darkgray-d",
    cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
  }

  return (
    <div className={styles.wrapper}>

      <header className={styles.header}>

        <div>
          <h2 className="text-lg">{gruppo.nome}</h2>
          <p className="mt-2">{gruppo.utenti && gruppo.utenti.length} {t('ModuloGestioneUtenti.GruppoTile.utente')}</p>
        </div>

        <div className="flex gap-3 items-center">
          <div onClick={() => { setAperto(!aperto) }}>
            {aperto
              ?
              <FaAngleUp className={styles.button} size={15} />
              :
              <FaAngleDown className={styles.button} size={15} />
            }
          </div>

          {privilegio >= 3 &&
            <div className="relative" onClick={() => toggleContextMenu()}>

              <FaEllipsisV className={styles.button} />
              {contextMenu && <GruppoContextMenu gruppo={gruppo} />}

            </div>
          }

        </div>
      </header>


      {aperto &&
        <>
          <div className={styles.body}>
            <hr className="border-lightgray-d dark:border-darkgray" />
            <div>
              <p className="text-text-light dark:text-dark-text-light my-6"> {gruppo.descrizione} </p>
              <div className={styles.cardsWrapper}>

              {gruppo.utenti && gruppo.utenti.map((utente, i) => <UtenteTile key={i} utente={utente} />)}

                {privilegio >= 2 &&
                  <div onClick={() => ui.mountAsideModal(<AggiungiUtentiGruppoForm gruppo={gruppo} />)}>
                    <AggiungiButton testo={t('ModuloGestioneUtenti.GruppoTile.collegaUtente')} />
                  </div>
                }
              </div>

            </div>
          </div>
        </>
      }

    </div>
  )
})

export default GruppoTile;
import React from 'react'
import { useModuloRendicontazioneBilanciStores } from '../context/useModuloRendicontazioneBilanciStores'
import { observer } from 'mobx-react-lite'
import { IBusinessUnitRendicontabile } from '../../../types'
import { Link, useParams } from 'react-router-dom'
import Spinner from '../../../../../components/common/Spinner'

const ListaBusinessUnitsRendicontabili = observer(() => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const { business_unit_nome } = useParams()
    return (
        <div className="my-6 flex flex-col lg:flex-row lg:mb-0 md:max-w-[600px] max-h-[400px] overflow-auto">

            {rendicontazione_bilanci.bilancio && rendicontazione_bilanci.bilancio.business_units_rendicontabili.map((businessUnit: IBusinessUnitRendicontabile) => {
                return (
                    <Link
                        className={`
                        p-3 cursor-pointer bg-white dark:bg-darkgray-dd duration-200 relative flex items-center justify-center
                        ${rendicontazione_bilanci.gettingBusinessUnit && 'pointer-events-none opacity-50'}
                        ${rendicontazione_bilanci.businessUnitAttiva &&
                                businessUnit.nome === rendicontazione_bilanci.businessUnitAttiva.nome ?
                                'border-l-4 md:border-l-0 md:border-t-4 border-accent dark:border-accent pointer-events-none'
                                : 'opacity-50'
                            }`}
                        key={businessUnit.nome}
                        to={businessUnit.nome}
                    >
                        <span className={`block whitespace-nowrap ${business_unit_nome === businessUnit.nome && rendicontazione_bilanci.gettingBusinessUnit && 'opacity-0'}`}>
                            {businessUnit.nome}
                        </span>

                        {
                            business_unit_nome === businessUnit.nome && rendicontazione_bilanci.gettingBusinessUnit &&
                            <div className="absolute">
                                <Spinner colore="blue" />
                            </div>
                        }

                    </Link>
                )
            })}

        </div>
    )
})

export default ListaBusinessUnitsRendicontabili; 
import { useEffect } from 'react'
import { useStores } from './useStores'

const useSettaModuliUtente = () => {

  const { auth } = useStores()

  useEffect(() => {

    auth.settaCodiciModuli()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

}

export default useSettaModuliUtente;
import React, { useState } from 'react'

//Data
import { ILingua } from '../../../types';
import useOttieniPrivilegioModulo from '../../../hooks/useOttieniPrivilegioModulo';

//Components
import { FaEllipsisV } from 'react-icons/fa';
import LinguaContextMenu from './LinguaContextMenu';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const UnitaMisuraTile: React.FC<{ lingua: ILingua }> = ({ lingua }) => {

    const privilegio = useOttieniPrivilegioModulo();
    const [contextMenu, setContextMenu] = useState<boolean>(false);

    const toggleContextMenu = () => {
        //Il timeout permette di far comparire il context menu dopo che l'utente ha cliccato
        setTimeout(() => {
            setContextMenu(!contextMenu)
        }, 1)
    }

    document.addEventListener('click', () => {
        if (contextMenu) {
            setContextMenu(false)
        }
    }, { once: true })

    return (
        <div className="bg-white dark:bg-black p-4 rounded-sm shadow-xl">
            <div className="flex items-center justify-between">
                <h2 className="text-lg font-bold flex gap-2 items-center">
                    <span className={`fi fi-${lingua.bandiera}`} />
                    {lingua.nome}
                </h2>

               {privilegio >= 3 &&
                    <div className="cursor-pointer hover:bg-lightgray p-1 relative" onClick={() => toggleContextMenu()}>
                        <FaEllipsisV />

                        {contextMenu &&
                            <LinguaContextMenu lingua={lingua} />
                        }
                    </div>
               }

            </div>
        </div>
    )
}

export default UnitaMisuraTile;
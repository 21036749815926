import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useStores } from '../../hooks/useStores'
import { observer } from 'mobx-react-lite'

//Components
import { FaAngleDown, FaAngleUp, FaInfoCircle } from 'react-icons/fa'
import RichiesteArchiviateModal from './RichiesteArchiviateModal'
import TileRichiestaUtente from './RichiestaUtenteTile'

export const PannelloRichieste = observer(() => {
    const { t } = useTranslation();

    const [mostraRichiesteArchiviate, setMostraRichiesteArchiviate] = useState(false);

    const { gestioneUtenti } = useStores();

    const styles = {
        cardsWrapper: "grid gap-3 xl:gap-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
        requestsButton: "cursor-pointer hover:text-accent rounded-sm p-2 hover:bg-lightgray-d flex gap-4 items-center",
        headerPannello: "flex items-center justify-between flex-col md:flex-row my-5",
        buttonRichiesteGestite: `relative flex md:block justify-center  ${gestioneUtenti.richieste.filter(richiesta => richiesta.gestita === true).length < 1 && 'opacity-70 pointer-events-none'}`
    }

    useEffect(() => {
        gestioneUtenti.getRichieste()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gestioneUtenti.editingRichiesta])

    return (
        <div>
            <div className={styles.headerPannello}>
                <h2 className="text-2xl">{t('ModuloGestioneUtenti.PannelloRichieste.richiesteAperte')}</h2>
                <div className={styles.buttonRichiesteGestite}>
                    <p onClick={() => setMostraRichiesteArchiviate(!mostraRichiesteArchiviate)} className={styles.requestsButton}>

                        <span>{t('ModuloGestioneUtenti.PannelloRichieste.richiesteGestite')} ({gestioneUtenti.richieste.filter(richiesta => richiesta.gestita === true).length})</span>

                        {mostraRichiesteArchiviate ? <FaAngleUp /> : <FaAngleDown />}

                    </p>

                    {mostraRichiesteArchiviate && <RichiesteArchiviateModal />}
                </div>

            </div>

            {
                gestioneUtenti.richieste
                    .filter(richiesta => richiesta.gestita === false).length >= 1 ?
                    (
                        <div className={styles.cardsWrapper}>
                            {gestioneUtenti.richieste
                                .filter(richiesta => richiesta.gestita === false)
                                .map((richiesta, i) => <TileRichiestaUtente key={i} richiesta={richiesta} />)}
                        </div>
                    ) : (
                        <p className="flex items-center gap-2 opacity-70 justify-center">
                            <FaInfoCircle /> <span className="text-lg">{t('ModuloGestioneUtenti.PannelloRichieste.nessunoRichiesta')}</span>
                        </p>
                    )}

        </div>
    )
}
)
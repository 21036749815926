export const en_modulo_gestione_lingue = {
    ContextMenu: {
        linguaEliminata: 'Language successfully deleted',
        modificaLingua: 'Edit language',
        messagioElimina: 'Are you sure you want to delete this language?',
        eliminaLingua: 'Delete language',
    },

    Archivio: {
        nessunaLingua: 'No language available',
        nuovaLingua: 'Add new language',
    },

    Form: {
        modificaLingua: 'Edit language',
        creaLingua: 'Create language.',
        modificaLinguaTesto: 'Edit language data',
        placeholderCodice: 'it, en, ru',
        messaggiLinguaCreatoSucesso: 'Language successfully created',
    },
}
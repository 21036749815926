import React from 'react'

//Data
import { useTranslation } from 'react-i18next'

//Components
import { Link } from 'react-router-dom'
import { LoginForm } from '../components/auth/LoginForm'

export default function Login() {
  const { t } = useTranslation();

  return (
    <div className="w-full">

      <h1 className="text-4xl text-text">{t('paginaLogin.accessAccount')}</h1>
      <p className="text-text-light mt-3 mb-5">{t('paginaLogin.messaggioLoginGenerale')}</p>

      <LoginForm />

      <div className="mt-3 text-center">
        <Link className="block" to="/signup">{t('common.richiediAccesso')}</Link>
        <Link className="block mt-5" to="/richiedi-reset-password">{t('paginaLogin.dimenticatoPassword')}</Link>
      </div>

    </div>
  )
}